.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.inner-container{
    width: 95%;
    display: flex;
    flex-direction: row;
    margin: 2rem;
}
.form-contact{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.input-container{
    display: flex;
    flex-direction: column;   
}
.form-message{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.message{
    height: 100%;
}
.input{
    display: block;
    min-width: 90%;
}
.border-input{
    margin: 2%;
    padding: 2%;
    border-radius: 8px;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;  
}
.message:hover{
    border-color: #3578ea;
}



.input:hover {
    border-color: #3578ea;
}
  
.field-name{
    color: var(--headlineColor2);
    letter-spacing: 4.3px;
    font-size: 1.5rem;
    padding-left: 2%;
}

button{
    background-color: #3578ea;
    color:white;
    margin: 1%;
    padding: 1%;
    border-radius: 8px;
    border-style: none;
    transition: 0.1s ease;
}
button:hover{
    border-width: 3px;
}

@media only screen and (max-width:1024px){
    .inner-container{
        flex-direction: column;
    }
}