.contact-page{
    background-color: var(--background);
    height: 20vh;
    display: flex;
    align-content: center;
    flex-direction: column;
}
.footer{
    margin-top: 4rem;
    padding: 1rem;
    height: 4rem;
    display: flex;
    align-items: center;
}
.icons-container{
    width: 80%;
    display: flex;
    justify-content: space-around;
}
.icon-link{
    color:var(--textColor)
}
.icons{
    vertical-align: middle;
    font-size: 2rem;   
}