.home-page {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    background: var(--backgroundGradient);
    height: 100vh;
    width: 100vw;
  }

.home-page-title{
  padding-left: 5%;
}
/*background: linear-gradient(190deg, rgb(255, 251, 236) 0%, rgb(227, 254, 255) 50%, rgb(255, 243, 253) 84%);*/
#canvas{
  position:absolute;
  width: 100vw;
}