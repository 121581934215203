*{
  margin: 0;
  padding: 0;
  scrollbar-width: none;
}
*::-webkit-scrollbar{
  display: none;
}
.light-mode{
  --gradientColor1:#00a3ee;
  --gradientColor2:#013852;
  --backgroundGradient:linear-gradient(190deg, rgb(255, 251, 236) 0%, rgb(227, 254, 255) 50%, rgb(255, 243, 253) 84%);
  --background:#ffffff;

  --headlineColor1:#1c2b33;
  --headlineColor2:#1c2b33;
  --headlineColor3:#00a3ee;
  --borderGradient: radial-gradient(circle at top left, #00a3ee,#013852);
  --borderStyle: 2px solid #d1d2d4;
  
  --textColor:#000000;
  --navBarColor:#00245e;
}
.dark-mode{
  --gradientColor1: #FE00AE;
  --gradientColor2: #8E39C5;

  --backgroundGradient: linear-gradient(190deg, rgb(33, 37, 41) 0%, rgb(13, 17, 23) 50%, rgb(23, 21, 35) 84%);
  --background:#0b0c18;

  --headlineColor1:#ffffff;
  --headlineColor2:#ffffff;
  --headlineColor3:-webkit-linear-gradient(180deg,#FE00AE, #8E39C5);
  --borderGradient: radial-gradient(circle at top left, #FE00AE,#8E39C5);


  --textColor:#ffffff;
  --navBarColor:#ffffff;
}
.changeApperanceButton {
  background: transparent;
  height: 40px; /* Gleiche Höhe */
  width: 40px; /* Gleiche Breite */
  font-size: 1.2rem;
  position: fixed;
  z-index: 11;
  bottom: 0px;
  left: 0px; 
  padding: 0; /* Entfernt Browser-Standard-Padding */

  /*Border*/
  box-shadow: 0 10px 20px rgba(84, 84, 84, 0.326);
  border: solid 2px transparent;
  border-radius: 0.5rem;
  background-image: linear-gradient(var(--background),var(--background)), radial-gradient(circle at left, var(--gradientColor1),var(--gradientColor2));
  background-origin: border-box;
  background-clip: padding-box, border-box;

  color:var(--textColor);
  border-image: var(--borderGradient) 1;
}


:root{
  --headlineSize1:10rem;
  --headlineSize2:5em;
  --headlineSize3:1.2em;

  --fontSize1:1.2em;
  --fontSize2:1.1em;
  --fontSizeCards:0.8em;
}
h1{
  background: var(--headlineColor1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: var(--headlineSize1);
}
h2{
  background: var(--headlineColor2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: var(--headlineSize2);
  margin-top: 1em;
  
}
h3{
  background: var(--headlineColor3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: var(--headlineSize3);
  letter-spacing: 4.0px;
  
}
h4{   
  background: var(--headlineColor2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;                                               
  padding-top: 1%;
  font-size: var(--fontSize2);
  font-weight: normal;
}
h5{
  background: var(--headlineColor1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 1%;
  font-size: var(--fontSizeCards);
  font-weight: normal;
}
p{
  color:var(--textColor);
  font-size: var(--fontSize2);
}
.border-floating{
  border-radius: 20px;
  border: 2px solid #e4e6e8;
  box-shadow: 0 10px 20px rgba(84, 84, 84, 0.326);
}
.page{
  padding:5%;
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 800px) {
  h1{
    font-size: 15vw;
  }
  h2{
    font-size: 6vw;
  }
  h3{
    font-size: 3vw;
  }
}