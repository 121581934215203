@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

header{
    display: flex;
    align-items: center;

    height: 60px;
    width: 100%;
    padding: 0 2rem;
    font-size: 1.2rem;

    position: fixed;
    z-index: 10;
}
nav{
    display: flex;
    align-items: center;
}

nav a{
    margin:0 3rem;
    color: var(--navBarColor);
    text-decoration: none;
}
nav a:hover{
    opacity: 1;
    text-decoration: underline;
    cursor: pointer;
}
.active {
    font-weight: bold;
}
header .nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}


@media only screen and (max-width:1024px){
    header .nav-btn{
        visibility: visible;
        opacity: 1;
    }

    header nav{
        position: fixed;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        transition: 1s;
        transform: translateY(-150vh);
        /*background-color: var(--background);*/
    }

    header .responsive_nav{
        transform: none;
    }

    nav .nav-close-btn{
        position: absolute;
        top:2rem;
        right: 2rem;
    }

    nav a{
        font-size: 1.5rem;
    }
}