.about-page{
    display: flex;
    align-content: center;
    background-color: var(--background);
    
}

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
}

.left-side{
    width: 45%;
}
.right-side{
    width: 45%;
}
.mid-border{
    border: 2px solid grey;
    border-radius: 3px;
}

@media only screen and (max-width:1024px){
    .container{
        flex-direction: column;
    }
    .left-side{
        width: 100%;
    }
    .right-side{
        padding-top: 1rem;
        width: 100%;
    }
    .mid-border{
        border: none;
    }
}