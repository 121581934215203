.karte {
    display: inline-flex;
    align-items: center;

    margin: 20px;
    padding:10px;


    /*Border*/
    box-shadow: 0 10px 20px rgba(84, 84, 84, 0.326);
    border: solid 2px transparent;
    border-radius: 0.5rem;
    background-image: linear-gradient(var(--background),var(--background)), radial-gradient(circle at left, var(--gradientColor1),var(--gradientColor2));
    background-origin: border-box;
    background-clip: padding-box, border-box;

    color:var(--textColor);
  }
  
  .karte img {
    width: 1.5em;
    height: 1.5em; 
    margin-right: 10px; 
  }
  
  .karte p {
    display: inline-block;  
  }

  
  