.skill-page{
    flex-direction: column;
    background-color: var(--background);
}
.card-box{
    /*border:2px solid red;*/
    height: 10vh;
}
.skills-container{
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card-container{
    position: relative;

    display: flex;
    flex-direction: row;
    align-items:center; 
    align-content: stretch;
    justify-content: space-between;
    min-height: 20vh;
}
@media only screen and (max-width:1024px){
    .skills-container{
        height: 200vh;
        margin-bottom: 10vh;
    }
    .card-container{
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
    }
    .card-box{
        margin:50px;
    }
}